import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "gatsby";

import SEO from "../components/seo";
import withLayout from "../layout";

import { SEO_KEYWORDS } from "../constants";

import "./index.scss";

const SKILL_D3 = 4;
const SKILL_PRODUCT_DEVELOPMENT = 1;
const SKILL_LIBRARIES = 2;

const HomePage = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "home.title" });
    const [skill, setSkill] = useState(null);

    useEffect(() => {
        setSkill(SKILL_PRODUCT_DEVELOPMENT);
    }, []);

    return (
        <article className="homepage">
            <header className="homepage__header">
                <SEO
                    title={titleText}
                    keywords={SEO_KEYWORDS}
                    lang={props.pageContext.locale}
                />
            </header>
            <main
                className="home"
                role="main"
                itemScope
                itemType="http://schema.org/WebPage"
            >
                <div className="container">
                    <section className="home-menu">
                        <div className="home-menu-bd">
                            <nav role="navigation">
                                <ul className="home-menu-list clearfix">
                                    <li
                                        className={
                                            skill === SKILL_D3
                                                ? "menu-item active"
                                                : "menu-item"
                                        }
                                    >
                                        <h2 className="h3">
                                            <button
                                                className="link button-link"
                                                type="button"
                                                onClick={() =>
                                                    setSkill(SKILL_D3)
                                                }
                                                title="Check my last Dashboard and Dataviz projects"
                                            >
                                                <FormattedMessage id="home.dashboards-title" />
                                            </button>
                                            <span
                                                className="nice-line"
                                                role="presentation"
                                            >
                                                <b></b>
                                            </span>
                                        </h2>
                                    </li>
                                    <li
                                        className={
                                            skill === SKILL_PRODUCT_DEVELOPMENT
                                                ? "menu-item active"
                                                : "menu-item"
                                        }
                                    >
                                        <h2 className="h3">
                                            <button
                                                className="link button-link"
                                                type="button"
                                                onClick={() =>
                                                    setSkill(
                                                        SKILL_PRODUCT_DEVELOPMENT
                                                    )
                                                }
                                                title="Check my last Product Development projects"
                                            >
                                                <FormattedMessage id="home.product-development-title" />
                                            </button>
                                            <span
                                                className="nice-line"
                                                role="presentation"
                                            >
                                                <b></b>
                                            </span>
                                        </h2>
                                    </li>
                                    <li
                                        className={
                                            skill === SKILL_LIBRARIES
                                                ? "menu-item active"
                                                : "menu-item"
                                        }
                                    >
                                        <h2 className="h3">
                                            <button
                                                className="link button-link"
                                                type="button"
                                                onClick={() =>
                                                    setSkill(SKILL_LIBRARIES)
                                                }
                                                title="Check my last Library and Design Systems projects"
                                            >
                                                <FormattedMessage id="home.design-systems-title" />
                                            </button>
                                            <span
                                                className="nice-line"
                                                role="presentation"
                                            >
                                                <b></b>
                                            </span>
                                        </h2>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </section>
                    <section className="home-skills">
                        {skill === SKILL_D3 && (
                            <section className="skill skill-4">
                                <div
                                    className="skill-bd"
                                    id="skill-description-region"
                                >
                                    <h1 className="h3 pbm">
                                        <FormattedMessage id="home.dashboards-subtitle" />
                                    </h1>
                                </div>
                                <div
                                    className="skill-ft"
                                    id="skill-projects-region"
                                >
                                    <nav
                                        className="skill-projects"
                                        role="navigation"
                                    >
                                        <ul className="skill-projects plain-list pan mvs">
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/oss-britecharts"
                                                        title="Check more about this project"
                                                    >
                                                        Britecharts Charting
                                                        Library
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Britecharts is a reusable
                                                    Charting Library based on
                                                    D3.js v5. It allows the
                                                    intuitive use of charts and
                                                    components that you can
                                                    compose, creating beautiful
                                                    interactive data
                                                    visualizations.
                                                </p>
                                            </li>
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/eventbrite-organizer-dashboard"
                                                        title="Check more about this project"
                                                    >
                                                        Eventbrite's Organizer
                                                        Dashboard
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    The Legacy Event Dashboard
                                                    was a highly trafficked
                                                    landing page for event
                                                    creators' data. Read how I
                                                    evolved its architecture and
                                                    aesthetics to achieve 15%
                                                    more visits.
                                                </p>
                                            </li>
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/sparked-retention-radar"
                                                        title="Check more about this project"
                                                    >
                                                        Retention Radar Web
                                                        Application
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Retention Radar makes use of
                                                    machine learning, natural
                                                    language processing and
                                                    advanced data analysis to
                                                    help subscription based
                                                    services reduce their churn
                                                    rate.
                                                </p>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </section>
                        )}
                        {skill === SKILL_PRODUCT_DEVELOPMENT && (
                            <section className="skill skill-1">
                                <div
                                    className="skill-bd"
                                    id="skill-description-region"
                                >
                                    <h1 className="h3 pbm">
                                        <FormattedMessage id="home.product-development-subtitle" />
                                    </h1>
                                </div>
                                <div
                                    className="skill-ft"
                                    id="skill-projects-region"
                                >
                                    <nav
                                        className="skill-projects"
                                        role="navigation"
                                    >
                                        <ul className="skill-projects plain-list pan mvs">
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/lyft-verity/"
                                                        title="Check more about this project"
                                                    >
                                                        Verity - Data Quality
                                                        Tool
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Verity is a data quality
                                                    check tool aimed to allow
                                                    improved data trust to
                                                    enable data-driven
                                                    decisions. Read how it
                                                    evolved to verify 25TB of
                                                    data and be adopted by 65
                                                    teams.
                                                </p>
                                            </li>
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/oss-amundsen"
                                                        title="Check more about this project"
                                                    >
                                                        Amundsen - Data Catalog
                                                        Tool
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Amundsen is an open source
                                                    metadata-driven data
                                                    discovery and catalog tool.
                                                    It improves the productivity
                                                    of data analyst, data
                                                    scientists and engineers.
                                                    Keep on reading to learn how
                                                    it got its 3.9K stars in
                                                    GitHub.
                                                </p>
                                            </li>
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/eventbrite-follow-organizer"
                                                        title="Check more about this project"
                                                    >
                                                        Eventbrite's Follow
                                                        Organizer
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Follow Organizer was an
                                                    under-utilized feature that
                                                    had the potential to
                                                    increase attendee engagement
                                                    with event creators. Read
                                                    how we sold up to 1.5M
                                                    ticket and raised 1% the
                                                    company's revenue.
                                                </p>
                                            </li>
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/eventbrite-listing"
                                                        title="Check more about this project"
                                                    >
                                                        Eventbrite's Event
                                                        Listing
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    The Event Listing is the
                                                    main entry point for selling
                                                    tickets at Eventbrite. Read
                                                    how we improved its
                                                    conversion rate by 45% on
                                                    desktop and 100% on mobile
                                                    web, affecting millions of
                                                    users.
                                                </p>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </section>
                        )}
                        {skill === SKILL_LIBRARIES && (
                            <section className="skill skill-2">
                                <div
                                    className="skill-bd"
                                    id="skill-description-region"
                                >
                                    <h1 className="h3 pbm">
                                        <FormattedMessage id="home.design-systems-subtitle" />
                                    </h1>
                                </div>
                                <div
                                    className="skill-ft"
                                    id="skill-projects-region"
                                >
                                    <nav
                                        className="skill-projects"
                                        role="navigation"
                                    >
                                        <ul className="skill-projects plain-list pan mvs">
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/lyft-lpl"
                                                        title="Check more about this project"
                                                    >
                                                        Lyft's Product Language
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Lyft's Product Language
                                                    (LPL) is a multi-platform
                                                    Design System with a 98%
                                                    product adoption within
                                                    Lyft. Read how I helped the
                                                    project to be more
                                                    accessible and complete.
                                                </p>
                                            </li>
                                            <li className="project-item">
                                                <h3 className="h3 project-title">
                                                    <Link
                                                        className="link"
                                                        to="/projects/oss-britecharts"
                                                        title="Check more about this project"
                                                    >
                                                        Britecharts - Reusable
                                                        Charting Library
                                                    </Link>
                                                </h3>
                                                <p className="project-description">
                                                    Britecharts is a reusable
                                                    Charting Library based on
                                                    D3.js. It allows you to
                                                    compose charts intuitively,
                                                    creating beautiful
                                                    interactive data
                                                    visualizations. Read more
                                                    about this popular project
                                                    that amassed 3.7K stars on
                                                    GitHub.
                                                </p>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </section>
                        )}
                    </section>
                </div>
            </main>
        </article>
    );
};

const customProps = {
    localeKey: "home",
};

export default withLayout(customProps)(injectIntl(HomePage));
